import React, { useEffect, useState } from "react"
import buttons from "../../../images/icon_button.png"
import joysticks from "../../../images/icon_joystick.png"
import hybrid from "../../../images/icon_hybrid_hard.png"
import hybrid_hard from "../../../images/icon_hybrid_hard.png"
import touch from "../../../images/icon_touch_hard.png"
import BasicButton from "../../../assets/components/BasicButton"
import { VolumeUp, VolumeMute, VolumeDown, VolumeOff } from "@mui/icons-material"

import { Box, Slider, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"
import { usePostMqttChangeVolume } from "../../../mqtt/mqttHandlerContext"
import { InstrumentType, SONG_TYPES, Song, Instrument, TouchMode } from "../../../utils/types"
import { Drawer } from "../Welcome"
import Text from "../../../assets/components/Text"
import InstrumentSettings from "../Components/InstrumentSettings"
import { useConnectedInstruments } from "../../../hooks/contexts/connectedInstrumentsContext"
import { useCurrentUser } from "../../../hooks/contexts/currentUserContext"
import AllSoundsAvailable, { toggleButtonGroupStyling, toggleButtonStyling } from "../Components/AllSoundsAvailable"
import Funkingen from "../../../assets/icons/funkingen_2.png"
import { useSetInstrumentMode } from "../../../hooks/contexts/currentSongContext"
import InstrumentInformation from "./Components/InstrumentInformation"
import { VolumeIcon } from "../../../components/DynamicVolumeIcon"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(type: InstrumentType, touchMode?: TouchMode): string {
    switch (type) {
        case "buttons":
            return buttons
        case "touch":
            return touch
        case "joysticks":
            return joysticks
        case "hybrid":
            return hybrid_hard
        default:
            return buttons
    }
}

interface InstrumentsProps {
    currentSong: Song | undefined
    instrumentVolumes: { [key: string]: number }
    setInstrumentVolumes: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>
    connectedInstruments: Instrument[]
}

const Instruments = ({ currentSong, instrumentVolumes, setInstrumentVolumes, connectedInstruments }: InstrumentsProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedInstrument, setSelectedInstrument] = useState<Instrument>({ deviceId: "", type: "", serialNr: "", color: "" })

    const postMqttChangeVolume = usePostMqttChangeVolume()
    const isSmallScreen = useIsSmallScreen()

    const currentUser = useCurrentUser()
    const songList = currentUser?.songList
    const shouldShowSettings = currentSong?.type === SONG_TYPES.KEY

    const toggleDrawer = ({ instrumentId, instrumentType, instrumentSerialNr, color }: { instrumentId?: string; instrumentType?: InstrumentType; instrumentSerialNr?: string; color?: string }) => {
        setIsOpen(!isOpen)
        setSelectedInstrument({
            color: color ?? "",
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    const handleSlider = (event: React.ChangeEvent<HTMLInputElement>, instrument: any) => {
        const newVolume = parseFloat(event.target.value)
        setInstrumentVolumes((prevVolumes: any) => ({
            ...prevVolumes,
            [instrument]: newVolume,
        }))
        postMqttChangeVolume(newVolume.toString(), instrument)
    }

    // Fetch initial volume values from storage when the component mounts
    useEffect(() => {
        const storedVolumesString = localStorage.getItem("instrumentVolumes")
        if (storedVolumesString) {
            const storedVolumes = JSON.parse(storedVolumesString)
            setInstrumentVolumes(storedVolumes)
        }
    }, [])

    // Save volume values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentVolumes", JSON.stringify(instrumentVolumes))
    }, [instrumentVolumes])

    return connectedInstruments && connectedInstruments.length > 0 ? (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
                gap: isSmallScreen ? "0rem" : "1rem",
                height: isSmallScreen ? "60%" : "100%",
                rowGap: isSmallScreen ? "2rem" : "0rem",
            }}
        >
            {connectedInstruments.map((instrument: Instrument) => (
                <Box key={instrument.deviceId} sx={{ display: "flex", width: "100%", height: isSmallScreen ? "7rem" : "11rem" }}>
                    <Box sx={{ display: "flex", width: isSmallScreen ? "70%" : "auto" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {/* <VolumeUp fontSize="small" sx={{ mb: 1 }} /> */}
                            <VolumeIcon value={instrumentVolumes[instrument.deviceId]} />
                            <Slider
                                sx={{
                                    width: "5px",
                                    "& .MuiSlider-thumb": {
                                        color: "white",
                                        height: "5px",
                                        borderRadius: "2px",
                                    },
                                    "& .MuiSlider-track": {
                                        color: "#77e077",
                                    },
                                    "& .MuiSlider-rail": {
                                        color: "#acc4e4",
                                    },
                                    "& .MuiSlider-active": {
                                        color: "green",
                                    },
                                }}
                                aria-label="Slider"
                                orientation="vertical"
                                // valueLabelDisplay="auto"
                                value={instrumentVolumes[instrument.deviceId] ?? 0.05}
                                min={0}
                                max={0.3} //MAX for 0-serie PCB = 0.35. MAX for development board = 0.8
                                step={0.01}
                                onChange={(event: any) => handleSlider(event, instrument.deviceId)}
                            />
                        </Box>

                        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                            <img src={handleImageSource(instrument.type, instrument.touchMode)} alt="Bild på instrument" width={"300px"} style={{ position: "relative", zIndex: 1 }} />
                        </Box>
                    </Box>
                    {songList && (
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: isSmallScreen ? "30%" : "auto" }}>
                            <InstrumentInformation instrument={instrument} songList={songList} shouldShowSettings={shouldShowSettings} isSmallScreen={isSmallScreen} currentSong={currentSong} />
                        </Box>
                    )}

                    <Drawer variant="bottom" isOpen={isOpen} toggleDrawer={() => toggleDrawer({})} heading="Välj ljud">
                        <InstrumentSettings currentSong={currentSong} selectedInstrument={selectedInstrument} />
                    </Drawer>
                </Box>
            ))}
        </Box>
    ) : (
        <Box sx={{ height: "20%", display: "flex", alignItems: "center", justifyContent: isSmallScreen ? "left" : "center", paddingRight: isSmallScreen ? 0 : "10em" }}>
            <img src={Funkingen} alt="Funkingen" style={{ height: "100%" }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Text italic size={"heading"} textAlign="center">
                    Väntar på Funki instrument...
                </Text>
            </Box>
        </Box>
    )
}

export default Instruments
